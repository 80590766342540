import {
  ADD_RENEWAL_PRICE,
  SET_RENEWAL_PRICE,
} from "../actionTypes/renewalPrices";

export const setRenewalPrice = (price) => {
  return {
    type: SET_RENEWAL_PRICE,
    payload: price,
  };
};

export const addRenewalPrice = (price) => {
  return {
    type: ADD_RENEWAL_PRICE,
    payload: price,
  };
};
export default {
  setRenewalPrice,
  addRenewalPrice,
};
