import React, { forwardRef } from "react";
import { Link as InternalLink } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Location } from "@reach/router";

const Link = forwardRef(
  ({ external, children, href, className, location, activeStyle }, ref) => {
    if (external)
      return (
        <a
          ref={ref}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
        >
          {children}
        </a>
      );

    // smooth scroll
    const position = href?.indexOf("#");
    if (position && href.substring(0, position) === location?.pathname) {
      return (
        <span onClick={() => scrollTo(href.substring(1))} className={className}>
          {children}
        </span>
      );
    }

    return (
      <InternalLink
        ref={ref}
        to={href}
        className={className}
        activeStyle={activeStyle}
      >
        {children}
      </InternalLink>
    );
  }
);

export default (props) => {
  return (
    <Location>
      {(locationProps) => <Link {...locationProps} {...props} />}
    </Location>
  );
};
