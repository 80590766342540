import quotes from "./quotes";
import user from "./user";
import message from "./message";
import prices from "./prices";
import renewQuotes from "./renewQuotes";
import renewalPrices from "./renewalPrices";

const actions = {
  quotes,
  user,
  message,
  prices,
  renewQuotes,
  renewalPrices,
};

export default actions;
