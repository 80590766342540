import { SET_QUOTES, ADD_QUOTE } from "../actionTypes/quotes";

export const setQuotes = (quotes) => {
  return {
    type: SET_QUOTES,
    payload: quotes,
  };
};

export const addQuote = (quote) => {
  return {
    type: ADD_QUOTE,
    payload: quote,
  };
};

export default {
  setQuotes,
  addQuote,
};
