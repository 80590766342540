import { SET_PRICES, GET_PRICES } from "../actionTypes/prices";

export const setPrices = (prices) => {
  return {
    type: SET_PRICES,
    payload: prices,
  };
};

export const getPrices = (price) => {
  return {
    type: GET_PRICES,
    payload: price,
  };
};

export default {
  setPrices,
  getPrices,
};
