import {
  ADD_RENEWAL_QUOTE,
  SET_RENEWAL_QUOTE,
} from "../actionTypes/renewQuotes";

export const setRenewalQuote = (quote) => {
  return {
    type: SET_RENEWAL_QUOTE,
    payload: quote,
  };
};

export const addRenewalQuote = (quote) => {
  return {
    type: ADD_RENEWAL_QUOTE,
    payload: quote,
  };
};
export default {
  setRenewalQuote,
  addRenewalQuote,
};
